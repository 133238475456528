html,
body {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
  font-family: 'Plus Jakarta Sans';
}

details,
summary {
  text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent;
}

.ellipsis {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.always-scroll::-webkit-scrollbar {
 -webkit-appearance: none;
 width: 7px;
}

.always-scroll::-webkit-scrollbar-thumb {
 border-radius: 4px;
 background-color: rgba(0, 0, 0, .4);
 -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .4);
}
.Toastify__toast-body > div:last-child {
  white-space: pre-wrap;
}